<template>
  <section class="missions">
    <div class="container mx-auto px-4">
      <div class="missions__header">
        <h2 v-motion :initial="{opacity:0, y:100}" :visibleOnce="{opacity:1, y:0}" :delay="500" class="missions__header-headline">Nos missions</h2>
      </div>
      <div class="missions__container">
        <div v-motion :initial="{opacity:0, y:100}" :visibleOnce="{opacity:1, y:0}" :delay="800" class="missions__container-item">
          <img
            :src="require('@/assets/icons/mission-1-icon.svg')"
            alt=""
            class="missions__container-item-icon"
          />
          <h3 class="missions__container-item-title">Digitech</h3>
          <p class="missions__container-item-description">
            Digitaliser l'information financière et budgétaire pour permettre
            aux citoyens de constater la précision des prévisions budgétaires,
            l'exécution du budget annuel et les perspectives du prochain projet
            de loi de finances.
          </p>
          <atom-button :to="{name:'DataAndTools'}" type="link" class="missions__container-item-cta"
            >Découvrir</atom-button
          >
        </div>
        <div v-motion :initial="{opacity:0, y:100}" :visibleOnce="{opacity:1, y:0}" :delay="1100" class="missions__container-item">
          <img
            :src="require('@/assets/icons/mission-2-icon.svg')"
            alt=""
            class="missions__container-item-icon"
          />
          <h3 class="missions__container-item-title">E-Forum</h3>
          <p class="missions__container-item-description">
            Contribuer à une plus grande responsabilité démocratique des
            institutions publiques, et un contrôle parlementaire renforcé afin
            de faciliter l'accès des citoyens aux informations légales et
            juridiques.
          </p>
          <atom-button :to="{name:'ForumHome'}" type="link" class="missions__container-item-cta"
            >Découvrir</atom-button
          >
        </div>
        <div v-motion :initial="{opacity:0, y:100}" :visibleOnce="{opacity:1, y:0}" :delay="1400" class="missions__container-item">
          <img
            :src="require('@/assets/icons/mission-3-icon.svg')"
            alt=""
            class="missions__container-item-icon"
          />
          <h3 class="missions__container-item-title">E-Plaidoyer</h3>
          <p class="missions__container-item-description">
            Mener des actions de plaidoyer pour la publication des documents
            budgétaires, notamment conformément à la loi (31-13) pour émettre
            des suggestions et défendre des idées.
          </p>
          <atom-button type="link" :to="{name:'Advocacies'}" class="missions__container-item-cta"
            >Découvrir</atom-button
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.missions {
  @apply bg-light py-7 lg:py-8;
  &__header {
    &-headline {
      @apply text-lg font-medium mb-5 
        lg:mb-8 lg:text-xl;
    }
  }
  &__container {
    @apply lg:flex lg:gap-x-8 xl:gap-x-[50px];
    &-item {
      @apply mb-6;
      &-icon {
        @apply h-15 mb-7;
      }
      &-title {
        @apply text-lg font-medium mb-1;
      }
      &-description {
        @apply text-gray-base text-base mb-5;
      }
      &-cta {
        @apply text-sm py-2 px-3 rounded-md;
      }
    }
  }
}
</style>
