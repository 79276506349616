<template>
  <div class="chronogram" v-show="phases?.length">
    <div class="chronogram__header">
      <h2 v-motion :initial="{opacity:0, y:-100}" :visibleOnce="{opacity:1, y:0}" :delay="500" class="chronogram__heading">
        Processus d'élaboration et d'adoption du projet de loi de finance en temps réel.
      </h2>
    </div>
    <div class="chronogram__container">
      <div 
        v-for="(phase, index) in phases" :key="index" 
        @click="activeStepIndex = index" 
        :class="`
          chronogram__step 
          ${activeStepIndex ==  index ? 'chronogram__step--active':''}
          ${phaseStatus(phase) == 'current' ? 'chronogram__step--current':''}
          ${phaseStatus(phase) == 'future' ? 'chronogram__step--disabled':''}
          `">
        <picture>
          <img :src="imageUrl(phase.background.path)" alt="" class="chronogram__step-bg">
        </picture>
        <div class="chronogram__step-header">
          <span class="chronogram__step-number">
            0{{index+1}}
          </span>
          <h2 class="chronogram__step-title">
            {{phase.title}}
          </h2>
        </div>
        <div class="chronogram__step-body">
          <div class="chronogram__step-content">
            <h3 class="chronogram__step-heading">
              {{phase.title}}
            </h3>
            <div class="chronogram__step-list">
              <QuillEditor class="phase__container" v-model:content="phase.body" :options="options" />  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {computed, inject, onBeforeMount, ref} from "vue";
  import {useStore} from "vuex";
  import moment from "moment";
  const {state, dispatch} = useStore();
  const activeStepIndex = ref(0);
  const phases = computed(() => state.phases.phases?.map(phase => {
    if( typeof phase.body == 'string') phase.body = JSON.parse(phase.body)
    return phase;
  }));
  const imageUrl = inject('imageUrl');
  const options = {
    readOnly: true,
    modules:{
      toolbar: false
    }
  }
  const phaseStatus = (phase) => {
    if(moment(phase.startDate) <= moment()){
     return moment(phase.endDate) > moment() ? 'current':'past';
    }
    return 'future';
  };
  onBeforeMount(async () => {
    if(!phases.value?.length) {
      await dispatch('phases/getAll');
    }
  });
</script>

<style lang="scss">
  .chronogram{
    @apply bg-white;
    &__header{
      @apply container mx-auto px-4 pt-5;
    }
    &__heading{
      @apply text-dark text-lg leading-7 font-bold mb-4
      xl:text-[40px] xl:leading-[56px];
    }
    &__container{
      @apply flex flex-col xl:flex-row w-full xl:h-[50vh] xl:min-h-[500px]
      overflow-hidden bg-gradient-to-r from-black/80 to-transparent;
    }
    &__step{
      @apply relative flex flex-col xl:flex-row items-center flex-1 max-h-[100px]
      overflow-hidden 
      xl:max-h-full xl:max-w-[150px] 
      transition-all;
      &:first-child{
        .chronogram__step{
          &-header, &-body{
            @apply bg-dark/90;
          }
          &-heading{
            @apply before:content-['01'];
          }
        }
      }
      &:nth-child(2){
        .chronogram__step{
          &-header, &-body{
            @apply bg-dark/80;
          }
          &-heading{
            @apply before:content-['02'];
          }
        }
      }
      &:nth-child(3){
        .chronogram__step{
          &-header, &-body{
            @apply bg-dark/70;
          }
          &-heading{
            @apply before:content-['03'];
          }
        }
      }
      &:nth-child(4){
        .chronogram__step{
          &-header, &-body{
            @apply bg-dark/60;
          }
          &-heading{
            @apply before:content-['04'];
          }
        }
      }
      &--active{
        @apply min-h-[500px] max-h-full xl:min-h-full xl:max-w-[100vw];
        .chronogram__step{
          &-content{
            @apply opacity-100;
          }
          &-header{
            @apply xl:max-w-[150px]
          }
        }
      }
      &--current{
        .chronogram__step{
          &-header{
            @apply relative
            before:w-[150px] before:absolute 
            before:top-0 xl:before:top-[unset] xl:before:bottom-0 
            before:right-0
            before:capitalize before:text-center xl:before:rotate-180 
            before:bg-orange before:font-bold 
            before:py-0.5 sm:before:py-1 
            before:text-xs sm:before:text-sm;
            &:before{
              content:'Phase actuelle';
              text-orientation: initial;
              writing-mode:horizontal-tb;
            }
          }
        }
      }
      &--disabled{
        @apply pointer-events-none;
        .chronogram__step{
          &-bg{
            @apply hidden;
          }
        }
      }
      &-bg{
        @apply absolute inset-0 w-full h-full object-cover
        z-0;
      }
      &-header{
        @apply min-h-[100px] w-full flex gap-4 items-center leading-none
        uppercase 
      text-white
        cursor-pointer
        px-5 lg:px-8 xl:px-0
        xl:h-full xl:min-w-[150px] xl:justify-center
        xl:-rotate-180;
        @media (min-width:1280px) {
          writing-mode:vertical-rl;
          text-orientation: mixed;
        }
      }
      &-number{
        @apply text-base font-bold
        after:content-['|'];
      }
      &-title{
        @apply text-base font-bold;
      }
      &-body{
        @apply flex-1 w-full h-full text-white relative flex items-center
        px-5 lg:px-8 xl:px-20 pb-8 xl:pb-0
        lg:before:hidden
        lg:before:content-['']
        lg:before:w-full lg:before:h-full 
        lg:before:absolute lg:before:inset-0
        lg:before:z-[1]
        lg:before:rounded-md
        lg:before:bg-gradient-to-t from-black/10 to-black
        lg:before:opacity-70;
      }
      &-content{
        @apply relative z-10
        md:w-4/6 opacity-0
        transition-all;
      }
      &-list{
        @apply flex flex-col gap-3 max-h-[450px] overflow-y-auto pr-1 lg:pr-4;
        &::-webkit-scrollbar{
          @apply w-1;
        }
        &::-webkit-scrollbar-track{
          @apply bg-white/10 rounded-lg;
        }
        &::-webkit-scrollbar-thumb {
          @apply bg-white/80 rounded-lg;
        }
      }
      &-item{
        @apply text-[20px] leading-7;
      }
      &-indicator{
        @apply text-3xl font-black;
      }
      &-heading{
        @apply text-xl font-bold leading-[50px] mb-5 relative
        before:text-[200px] before:text-white/5
        before:absolute before:top-0;
      }
    }
  }
  .phase{
    &__container{
      &.ql-container.ql-snow{
        @apply border-none;
      }
      .ql-editor{
        @apply text-white border-none p-0 flex flex-col gap-2;
        ul{
          @apply pl-0 flex flex-col gap-2;
        }
        li{
          @apply pl-3;
        }
      }
      h1,h2,h3,h4,h5{
        @apply capitalize text-primary;
      }
      img{
        @apply max-h-[500px] object-cover;
      }
      p{
        @apply text-sm md:text-base lg:text-[18px] lg:leading-6 mb-2;
        &:first-of-type{
          @apply text-lg leading-8 font-medium;
        }
      }
      ul{
        @apply mb-2;
        li{
          @apply text-sm md:text-base lg:text-[18px] lg:leading-6;
        }
      }
    }
  }
</style>