<template>
  <section class="news">
    <div class="container mx-auto px-4">
      <div class="news__header">
        <h2 v-motion :initial="{opacity:0, y:-100}" :visibleOnce="{opacity:1, y:0}" :delay="500" class="news__header-headline">Actualités</h2>
        <router-link class="news__header-cta"
        v-motion :initial="{opacity:0, x:-50}" :visibleOnce="{opacity:1, x:0}" :delay="500"
        :to="{name: 'News' }"
        >
          <span
           class="news__header-cta-content"
            >Voir plus</span
          >
          <atom-icon
            class="news__header-cta-icon"
            icon="arrow-right"
          ></atom-icon>
        </router-link>
      </div>
      <div class="news__container">
        <Carousel v-if="news && news.length" :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="(article,index) in news.slice(0,6)" :key="index">
            <div v-motion :initial="{opacity:0, y:100}" :visibleOnce="{opacity:1, y:0}" :delay="500 + 300*(index+1)" class="news__container-item carousel__item">
              <div class="news__container-item-img-wrapper">
                <img
                  @click.prevent="router.push({name:'Article',params:{articleId:article.id}})"
                  :src="imageUrl(article.background.path)"
                  :alt="article.title"
                  class="news__container-item-img"
                />
                <img
                  :src="imageUrl(article.background.path)"
                  :alt="article.title"
                  class="news__container-item-blur"
                />
              </div>
              <h3 @click.prevent="router.push({name:'Article',params:{articleId:article.id}})" class="news__container-item-headline">
                {{article.title}}
              </h3>
              <p class="news__container-item-summary">
                {{article.summary}}
              </p>
              <atom-link
                variant="primary underline"
                class="news__container-item-link"
                :to="{name:'Article',params:{articleId:article.id}}"
                >Lire la suite</atom-link
              >
            </div>
          </Slide>
          <template #addons="{slideCount}">
            <Navigation v-if="slideCount > 3"/>
            <Pagination v-if="slideCount > 3"/>
          </template>
        </Carousel>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onBeforeMount, computed, toRefs, inject } from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
const {dispatch, state} = useStore();
const router = useRouter();
const props = defineProps({
  news:{
    type:Array,
    required:true
  }
});
const {news} = toRefs(props);
const imageUrl = inject('imageUrl');
const settings = {
  itemsToShow: 1,
  wrapAround: true,
  snapAlign: "left",
};
const breakpoints = {
  768: {
    itemsToShow: 1.5,
  },
  1024: {
    itemsToShow: 3,
  },
};
</script>

<style lang="scss" scoped>
.news {
  @apply bg-light py-7 xl:py-12;
  &__header {
    @apply flex justify-between mb-4 items-center;
    &-headline {
      @apply text-lg font-medium
        lg:text-xl;
    }
    &-cta {
      @apply text-sm flex bg-light px-2 py-1 items-center
        lg:bg-transparent;
      &-icon {
        @apply h-6;
      }
      &-content {
        @apply inline-block w-full mr-3 text-sm font-medium;
      }
    }
  }
  &__container {
    &-item {
      @apply text-left flex-1
      md:mr-2
      lg:mr-3
      xl:mr-5;
      &-img {
        &-wrapper{
          @apply w-full relative
          h-60  
          sm:h-72
          md:h-80
          rounded-lg
          shadow-sm
          cursor-pointer
          overflow-hidden;
        }
        @apply absolute inset-0 w-full h-full object-contain z-10;
      }
      &-blur{
        @apply absolute inset-0 top-1/2 -translate-y-1/2 w-full h-full brightness-50 blur-md z-0;
      }
      &-headline {
        @apply cursor-pointer font-medium text-lg mt-5 mb-1;
      }
      &-summary {
        @apply text-gray-base text-sm mb-5 lg:mr-1;
      }
      &-link {
        @apply mb-5;
      }
    }
  }
  .carousel {
    // &__track{
    //   @apply md:gap-8;
    // }
    &__slide{
      @apply items-start;
    }
    &__prev {
      @apply hidden;
    }
    &__next {
      @apply hidden
      absolute top-[160px] right-[-50px]
      md:block bg-primary;
      .carousel__icon{
        @apply relative left-[3px];
      }
    }
    &__pagination {
      @apply md:hidden;
      &-item {
        @apply flex-1;
      }
      &-button {
        @apply m-0 rounded-none w-full h-1 bg-light;
        &--active {
          @apply bg-primary;
        }
      }
    }
  }
}
</style>
