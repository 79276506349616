<template>
  <section class="finance-law">
    <div class="finance-law__container">
      <div class="finance-law__intro">
        <h2 v-motion :initial="{opacity:0, y:-100}" :enter="{opacity:1, y:0}" :delay="1500" class="finance-law__intro-headline">
          Processus d'élaboration et d'adoption du budget conformément aux dispositions de la LOF n°130-13
        </h2>
      </div>
      <div class="finance-law__chronogram">
        <div
          v-for="(item, key, index) in financeLawContent"
          :key="key"
          :class="`finance-law__chronogram-item ${
            openStatus[key]
              ? 'finance-law__chronogram-item--active'
              : ''
          }`"
          @click.prevent="updateStatus(key)"
        >
          <div class="container mx-auto px-4">
            <div
              class="finance-law__chronogram-item-header"
            >
              <atom-heading
                type="h3"
                variant="xs"
                class="finance-law__chronogram-item-header-title"
                :data-before="`0${index+1}`"
              >
                Phase de {{key}}
              </atom-heading>
              <atom-icon
                icon="arrow-down-s"
                class="finance-law__chronogram-item-header-icon"
              >
              </atom-icon>
            </div>
            <div class="finance-law__chronogram-item-body">
              <div class="finance-law__chronogram-item-dates-container">
                <atom-button
                  variant="white-outline full"
                  class="finance-law__chronogram-item-dates-element"
                  @click.prevent="expandEventContent(index)"
                  v-for="(phase, index) in item"
                  :key="index"
                >
                  {{phase.title}}
                </atom-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <molecule-modal-wrapper v-if="isModalOpen" :open="isModalOpen" @modalClose="isModalOpen=false">
      <div>
        <div class="flex justify-between items-center mb-4">
          <button @click="currentIndex--" 
          :class="`flex gap-2 items-center font-medium ${currentIndex > 0 ? 'text-primary' : 'pointer-events-none text-gray-300'}`">
            <span class="leading-0">Précedent</span>  
          </button>
          <button @click="currentIndex++"  v-if="currentIndex < financeLawContent[currentStatus]?.length - 1"
          class="flex gap-2 items-center font-medium text-primary">
            <span class="leading-0">Suivant</span>  
          </button>
          <button v-else @click="isModalOpen = false" class="flex gap-2 items-center font-medium text-primary">
            <span class="leading-0">Terminer</span>
          </button>
        </div>
        <h2 class="finance-law__modal-title">{{financeLawContent[currentStatus][currentIndex].title}}</h2>
        <div class="finance-law__modal-body" v-html="financeLawContent[currentStatus][currentIndex].body"></div>
      </div>
    </molecule-modal-wrapper>
  </section>
</template>

<script setup>
import { ref, computed } from "vue";
import MoleculeModalWrapper from "@/components/molecules/MoleculeModalWrapper.vue";
const isModalOpen = ref(false);
/* open status object (to store the open status of each phase) */
const openStatus = ref({
  programmation: true,
  consultation: false,
  traitement: false,
  adoption: false,
});
const currentStatus = computed( () => {
  for(const key in openStatus.value){
    if(openStatus.value[key]) return key;
  }
});
const currentIndex = ref(0);
/* Finance law content */
const financeLawContent = {
  programmation:[
    {
      title:"Avant le 15 Mars de l'année N-1",
      body:"<p>Diffusion aux Ministères de la circulaire du chef du Gouvernement relative à l'élaboration des propositions de programmations budgétaires triennales assorties des objectifs et des indicateurs de performance</p>"
    },
    {
      title:"Avant le 15 Mai de l'année N-1",
      body:"<p>Examen des propositions par les commissions de programmation et de performance regroupant les représentants du Ministère de l'Economie et des Finances et les ministères sectoriels</p>"
    },
        {
      title:"Avant le 15 Juillet de l'année N-1",
      body:`<p class="mb-1">Exposé du Ministre chargé</p>
        <p class="mb-1">Consultation avec le des ﬁnances en Conseil du Parlement Gouvernement sur:</p>
        <ul class="flex flex-col gap-1">
          <li>Diffusion aux Ministères</li>
          <li>L'état d'avancement de de la lettre d'orientation l'exécution de la loi de du Chef du ﬁnances en cours Gouvernement</li>
          <li>La programmation concernant la triennale des ressources préparation du PLF N et des charges de l'Etat</li>
          <li>Les grandes lignes du projet de loi de ﬁnances de l'année suivante</li>
        </ul>`
    },
  ],
  consultation:[
    {
      title:"Juillet de l'année N-1",
      body:`<ul>
      <li>Consultation avec le Parlement</li>
      <li>Diffusion aux Ministères de la lettre d'orientation du Chef du Gouvernement concernant la préparation du PLF N</li>
      </ul>`
    }
  ],
  traitement:[
    {
      title:"Septembre - Octobre de l'année N-1",
      body:`<img src=${require('@/assets/images/elaboration-1.png')} alt="Elaboration phase 2"/>`
    }
  ],
  adoption:[
    {
      title:"Octobre de l'année N-1",
      body:`<img src=${require('@/assets/images/adoption-1.png')} alt="Adoption phase 1"/>`
    },
    {
      title:"20 Octobre de l'année N-1",
      body:"Dépôt du PLF au bureau de la chambre des représentants",
    },
    {
      title:"Octobre - Décembre de l'année N-1",
      body:`<img src=${require('@/assets/images/adoption-3.png')} alt="Adoption phase 3"/>`
    }
  ]
}
/* Method to update the open status of a specific phase */
const updateStatus = (phase) => {
  for (const key in openStatus.value) {
    openStatus.value[key] = false;
  }
  openStatus.value[phase] = true;
};
const expandEventContent = (index) => {
  currentIndex.value = index;
  isModalOpen.value = true;
}
</script>

<style lang="scss" scoped>
.finance-law {
  @apply pt-5
    xl:pt-12 xl:bg-light;
  &__container {
  }
  &__intro {
    @apply container mx-auto px-4;
    &-headline {
      @apply text-dark text-lg leading-7 font-bold mb-4
      xl:text-[40px] xl:leading-[56px];
    }
  }
  &__chronogram {
    @apply bg-light
    xl:flex
    h-[500px];
    &-item {
      @apply relative py-3 max-h-[60px] overflow-hidden
      transition-all duration-1000
      xl:max-h-[550px] xl:box-border xl:bg-dark
      xl:py-8 xl:pl-5 xl:pr-0
      xl:flex xl:flex-col xl:flex-[3] xl:justify-center
      cursor-pointer
      ;
      &--active {
        @apply bg-primary text-white max-h-full
        xl:flex-[5];
        &:before {
          content: "";
          @apply absolute w-1 h-6 bg-primary -left-2;
        }
        .finance-law__chronogram-item {
          &-dates {
            &-container {
              @apply xl:block;
            }
          }
          &-header-icon {
            fill: white;
            transform: rotate(180deg);
            @apply fill-[#fff] xl:fill-[#000] xl:hidden;
          }
        }
      }
      &-header {
        @apply flex justify-between items-center cursor-pointer;
        &-title {
          @apply relative ml-4
          xl:text-white xl:text-lg xl:font-medium
          xl:mb-3 xl:ml-0;
          &:before {
            content: attr(data-before);
            @apply absolute -left-5
            xl:relative xl:block xl:left-0
            xl:text-3xl xl:font-black
            xl:mb-3;
          }
        }
        &-icon {
          @apply fill-[#000] xl:hidden;
        }
      }
      &-paragraph {
        @apply text-sm text-white/60 mt-1 mb-3 ml-4 mr-8
      xl:text-white/60
        xl:mb-4 xl:ml-0;
      }
      &-dates {
        &-container {
          @apply xl:hidden;
        }
        &-element {
          @apply mb-4;
        }
      }
    }
  }
  &__modal{
    &-title{
      @apply text-lg font-semibold mb-3;
    }
    &-body{
      @apply text-gray-600;
      ul{
        list-style-type: disc !important;
        li{
          list-style-type: disc;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
