<template>
  <section class="partners">
    <div class="">
      <div class="partners__header">
        <h2 
        v-motion :initial="{opacity:0, y:-100}" :visibleOnce="{opacity:1, y:0}" :delay="500"
        class="partners__header-headline">Nos Partenaires</h2>
      </div>
      <div class="partners__container">
        <img
          v-for="image in images"
          :src="image.src"
          :alt="image.alt"
          class="partners__container-item carousel__item"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    const images = [
      {
        src: require("@/assets/images/logos/partner1.png"),
        alt: "التكتل الجمعوي بطنجة الكبرى",
      },
      {
        src: require("@/assets/images/logos/partner2.png"),
        alt: "",
      }
    ];
    return {
      images
    };
  },
};
</script>

<style lang="scss">
.partners {
  @apply text-center py-5 lg:py-10 xl:py-12 bg-light;
  &__header {
    @apply mb-9 container mx-auto px-4
    lg:mb-10;
    &-headline {
      @apply text-lg font-medium lg:text-xl;
    }
    &-paragraph{
      @apply mx-auto max-w-2xl mt-2 text-gray-base;
    }
  }
  &__container {
    @apply flex flex-wrap justify-center items-center gap-20;
    &-item {
      @apply mb-7 h-20 object-contain;
    }
  }
}
</style>
