<template>
  <section class="coalition">
    <div class="">
      <div class="coalition__header">
        <h2 
        v-motion :initial="{opacity:0, y:-100}" :visibleOnce="{opacity:1, y:0}" :delay="500"
        class="coalition__header-headline">Coalition</h2>
      </div>
      <div class="coalition__container">
        <Carousel
          :settings="settings"
          :breakpoints="breakpoints"
        >
          <Slide v-for="(image, index) in images" :key="index">
            <img
              :src="image.src"
              :alt="image.alt"
              class="coalition__container-item carousel__item"
            />
          </Slide>
        </Carousel>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    const images = [
      {
        src: require("@/assets/images/logos/logo1.png"),
        alt: "التكتل الجمعوي بطنجة الكبرى",
      },
      {
        src: require("@/assets/images/logos/logo2.png"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo3.jpeg"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo4.png"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo5.png"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo6.png"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo7.jpg"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo8.jpg"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo9.png"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo10.jpg"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo11.png"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo12.png"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo13.png"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo14.png"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo15.png"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo16.png"),
        alt: "",
      },
      {
        src: require("@/assets/images/logos/logo17.png"),
        alt: "",
      },
    ];
    const settings = {
      itemsToShow: 2,
      itemsToScroll: 2,
      wrapAround: true,
      snapAlign: 'center',
      autoplay: 3000,
      transition:1000
    }
    const breakpoints = {
      768: {
        itemsToShow: 4
      },
      1024: {
        itemsToShow:  5
      },
      1280: {
        itemsToShow: 7
      },
      1536: {
        itemsToShow: 9
      }
    }
    return {
      images,
      settings,
      breakpoints
    };
  },
};
</script>

<style lang="scss">
.coalition{
  @apply text-center py-5 lg:py-10 xl:py-12 bg-white;
  &__header {
    @apply mb-9 container mx-auto px-4
    lg:mb-10;
    &-headline {
      @apply text-lg font-medium lg:text-xl;
    }
    &-paragraph{
      @apply mx-auto max-w-2xl mt-2 text-gray-base;
    }
  }
  &__container {
    &-item {
      @apply mb-7 h-12 object-contain;
    }
  }
}
</style>
