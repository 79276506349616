<template>
  <block-hero />
  <block-finance-law />
  <block-live-chronogram />
  <block-news v-if="news?.length" :news="news" />
  <block-advocacies v-if="publicAdvocacies?.length" :advocacies="publicAdvocacies" />
  <block-instagram-feed />
  <block-missions />
  <block-coalition />
  <block-partners />
</template>

<script setup>
import BlockHero from "@/components/blocks/BlockHero.vue";
import BlockFinanceLaw from "@/components/blocks/BlockFinanceLaw.vue";
import BlockLiveChronogram from "@/components/blocks/BlockLiveChronogram.vue";
import BlockNews from "@/components/blocks/BlockNews.vue";
import BlockAdvocacies from "@/components/blocks/BlockAdvocacies.vue";
import BlockInstagramFeed from "@/components/blocks/BlockInstagramFeed.vue";
import BlockMissions from "@/components/blocks/BlockMissions.vue";
import BlockCoalition from "@/components/blocks/BlockCoalition.vue";
import BlockPartners from "@/components/blocks/BlockPartners.vue";

import {computed, onBeforeMount} from "vue";
import {useStore} from "vuex";
const {state, dispatch} = useStore();

const news = computed( () => state.news.news?.data);
const publicAdvocacies = computed( () => state.news.publicAdvocacies?.data);
onBeforeMount( async () => {
  await dispatch('news/getAll',{
    page: 1,
    filter:'filter.isAdvocacy=false'
  });
  await dispatch('news/getAll',{
    page: 1,
    filter:'filter.isAdvocacy=true'
  });
});
</script>

<style lang="scss" scoped></style>
