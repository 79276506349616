<template>
  <header class="inset-x-0 top-0 z-50">
      <nav class="flex items-center justify-between container mx-auto px-4 py-6" aria-label="Global">
        <div class="flex lg:flex-1">
          <a href="#" class="-m-1.5 p-1.5">
            <img class="h-8 w-auto sr-only" :src="require('@/assets/images/logo-icon-white.png')" alt="" />
            <img class="h-8 w-auto" :src="require('@/assets/images/logo-white.png')" alt="" />
          </a>
        </div>
        <div class="flex lg:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400" @click="mobileMenuOpen = true">
            <span class="sr-only">Open main menu</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
        <div class="hidden lg:flex lg:gap-x-12">
            <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="{ name: item.routeName }"
                class="text-sm font-semibold leading-6 text-white"
            >
                {{ item.name }}
            </router-link>
        </div>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end">
            <router-link
                :to="{ name: 'ForumHome' }"
                class="text-sm font-semibold leading-6 text-white"
            >
                E-Forum
            </router-link>
        </div>
      </nav>
      <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-50" />
        <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div class="flex items-center justify-between">
            <a href="#" class="-m-1.5 p-1.5">
                <img class="h-8 w-auto sr-only" :src="require('@/assets/images/logo-icon-white.png')" alt="" />
                <img class="h-8 w-auto" :src="require('@/assets/images/logo-white.png')" alt="" />
            </a>
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-400" @click="mobileMenuOpen = false">
              <span class="sr-only">Close menu</span>
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/25">
              <div class="space-y-2 py-6">
                <router-link
                    v-for="item in navigation"
                    :key="item.name"
                    :to="{ name: item.routeName }"
                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                >
                    {{ item.name }}
                </router-link>
              </div>
              <div class="py-6">
                <router-link
                    :to="{ name: 'ForumHome' }"
                    class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                >
                    E-Forum
                </router-link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
</template>

<script setup>
import { ref } from 'vue';
import { Dialog, DialogPanel } from '@headlessui/vue';
// import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

const navigation = [
  { name: 'Accueil', routeName: 'Home' },
  { name: 'A propos', routeName: 'Explore' },
  { name: 'Coalition', routeName: 'Coalition' },
  { name: 'Digitech', routeName: 'DataAndTools' },
  { name: 'Contact', routeName: 'Contact' },
];

const mobileMenuOpen = ref(false);
</script>

<style lang="scss">

</style>