<template>
  <div class="ig-feed">
    <h2 v-motion :initial="{opacity:0, y:-100}" :visibleOnce="{opacity:1, y:0}" :delay="500" class="ig-feed__heading">
      Notre page instagram <br>@sancusmaroc
    </h2>
    <Carousel 
      v-if="igPosts && igPosts.length" 
      :settings="settings" 
      :breakpoints="breakpoints"
      class="ig-feed__container">
      <Slide v-for="(post,index) in igPosts" :key="index" class="ig-feed__item"
      v-motion :initial="{opacity:0, y:100}" :visibleOnce="{opacity:1, y:0}" :delay="500 + 300*(index+1)">
        <div class="ig-feed__header">
          <atom-image
            :src="require('@/assets/images/sancus-logo.jpg')"
            variant="rounded sm"
            class="ig-feed__avatar"
          />
          <span class="ig-feed__username">
            sancusmaroc
          </span>
        </div>
        <a 
          :href="post.permalink" 
          target="_blank"
          class="ig-feed__body"
          @click.prevent=""
          @mousedown="checkIfUserIsSliding"
          @mouseup="(event) => redirectToIgIfClicked(event, post.permalink)"
        >
          <template v-if="post.media_type == 'VIDEO'">
            <video 
              @mouseover="startPreview($event)" 
              @mouseleave="stopPreview($event)" 
              class="ig-feed__thumbnail" 
              :poster="post.thumbnail_url"
              muted
            >
                <source :src="post.media_url" class="ig-feed__thumbnail">
            </video>
            <svg class="ig-feed__icon" xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62">
              <g transform="translate(-658.451 -839.451)">
                <g transform="translate(658.451 839.451)" fill="none" stroke="#fff" stroke-width="3">
                  <circle cx="31" cy="31" r="31" stroke="none"/>
                  <circle cx="31" cy="31" r="29.5" fill="none"/>
                </g>
                <path d="M6.734,3.325a2,2,0,0,1,3.532,0l5.173,9.737A2,2,0,0,1,13.673,16H3.327a2,2,0,0,1-1.766-2.938Z" transform="translate(701.451 862.451) rotate(90)" fill="#fff"/>
              </g>
            </svg>   
          </template>
          <picture v-else>
            <img :src="post.media_url" alt="" class="ig-feed__thumbnail">
          </picture>
        </a>
      </Slide>
    </Carousel>
  </div>
</template>

<script setup>
  import {onBeforeMount, ref} from "vue";
  import IGService from "@/services/ig.service";
  const igService = new IGService();
  const igPosts = ref([]);
  const mouseMoved = ref(false);
  const settings = ref({
    itemsToShow:3,
    wrapAround: true,
    snapAlign:'left'
  });
  onBeforeMount(async () => {
    const fields = 'id,media_type,media_url, thumbnail_url, username,permalink';
    const limit = 10;
    try {
      const {data} = await igService.getMedia(fields,limit);
      igPosts.value = data;
    } catch (err) {
      console.log(err);
    }
  });
  const breakpoints = {
    0: {
      itemsToShow: 1
    },
    640: {
      itemsToShow: 2
    },
    1024: {
      itemsToShow:  3
    },
    1536: {
      itemsToShow: 4
    }
  }
  const startPreview = (e) => {
    const video = e.target;
    video.currentTime = 1;
    video.play();
  }
  const stopPreview = (e) => {
    const video = e.target;
    video.currentTime = 0;
    video.pause();
  }
  const checkIfUserIsSliding = (e) => {
    e.target.addEventListener('mousemove', setMouseMovedToTrue);
  }
  const setMouseMovedToTrue = () => {
    mouseMoved.value = true;
  }
  const redirectToIgIfClicked = (e, link) => {
    e.target.removeEventListener('mousemove', setMouseMovedToTrue);
    if(!mouseMoved.value) {
      window.open(link, '_blank');
    }
    mouseMoved.value = false;
  }
</script>

<style lang="scss" scoped>
  .ig-feed{
    @apply pt-10 pb-12;
    &__heading{
      @apply text-[36px] leading-[50px] font-medium text-black
      mb-[69px] container mx-auto px-4;
    }
    &__container{
      @apply
      container container--offset container--left ml-auto;
    }
    &__item{
      @apply flex flex-col border border-[#C4C4C4] rounded-[10px]
      px-3 py-5 items-start;
      &.carousel__slide--visible{
        @apply mr-8;
      }
    }
    &__header{
      @apply flex gap-3 items-center
      mb-5;
    }
    &__avatar{
      @apply shadow-lg h-[58px] w-[58px] rounded-full
      flex justify-center items-center text-white font-bold;
    }
    &__username{  
      @apply text-sm font-medium text-black
    }
    &__body{
      @apply min-w-full relative;
    }
    &__thumbnail{
      @apply h-[409px] object-cover mb-5 object-[0%_0%] rounded-md w-full brightness-90 cursor-pointer
      hover:brightness-100 lg:transition-all;
    }
    &__desc{
      @apply text-[14px] text-[#012230] opacity-60;
    }
    &__icon{
      @apply absolute w-9 right-[20px] bottom-8;
    }
  }
</style>