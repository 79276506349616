import { BaseService } from "./base.service";
import axios, { AxiosRequestConfig } from "axios";

export default class IGService extends BaseService {
  // Members
  private _BASE_API_URL: string = 'https://graph.instagram.com/me';

  public async getMedia(fields:string , limit:number): Promise<any> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"GET",
        url:`${this._BASE_API_URL}/media?fields=${fields}&limit=${limit}&access_token=${process.env.VUE_APP_IG_TOKEN}`,
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  public async getUser(fields: string): Promise<any> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"GET",
        url:`${this._BASE_API_URL}/?fields=${fields}&access_token=${process.env.VUE_APP_IG_TOKEN}`,
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
}
